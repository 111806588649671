import { LoginPage } from '@/modules/auth';

export async function getStaticProps() {
  return {
    props: {
      bodyClassName: 'login-page',
    },
  };
}

export default LoginPage;
